import { DefaultSeo } from 'next-seo'
import appWithI18n from 'next-translate/appWithI18n'
import getConfig from 'next/config'
import { AppContextType } from 'next/dist/next-server/lib/utils'
import DefaultErrorPage from 'next/error'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React from 'react'
import { QueryClientProvider } from 'react-query'
import i18nConfig from '../../i18n'
import { CartModal } from '../components/clotino/CartModal'
import { useCanonicalUrl } from '../hooks/useCanonicalUrl'
import { ClotinoContext } from '../hooks/useClotino'
import { ClotinoMotiveContext, useClotinoMotiveProviderValue } from '../hooks/useClotinoMotive'
import { useStorefrontContextValue } from '../hooks/useStorefrontContextValue'
import '../styles/main.sass'
import { useRefreshableServerSideProps } from '../utils/cachedServerSideProps/cachedServerSideProps'
import { appContextInfo } from '../utils/clotino/contextInfo'
import { localeFromStringFallback } from '../utils/clotino/localeFromString'
import { ClotinoLocale } from '../utils/clotino/Locales'
import { storeFromStringFallback } from '../utils/clotino/storeFromString'
import { ClotinoStore } from '../utils/clotino/Stores'
import { CustomAppProps } from '../utils/customApp'
import { queryClient } from '../utils/react-query'
import { StorefrontContext } from '../utils/shopify/storefront'
import { ProductInfoContext, usePrepareProductsInfo } from './[...fallback]'

function GtmInit(props: { pageType: 'HP' | 'checkout' | 'content' }) {
	const router = useRouter()
	const pageType = props.pageType ?? 'HP'

	const dimensions = {
		language: router.locale,
		pageType,
	}

	return (
		<Head>
			<script
				key="dimensions"
				dangerouslySetInnerHTML={{
					__html: `
		;(function(){
			function getCookie(name) {
				name = name + "=";
				var decodedCookie = decodeURIComponent(document.cookie);
				var cookies = decodedCookie.split(";");
					var cookie = ''
				for (let i = 0; i < cookies.length; i++) {
					cookie = cookies[i].trim();
					if (cookie.indexOf(name) == 0) {
						return cookie.substring(name.length, cookie.length);
					}
				}
			}

			var visited = getCookie('clotino_visited') === 'true';
			document.cookie = 'clotino_visited=true; path=/; max-age=' + String(30 * 24 * 60 * 60);
			window.dataLayer = window.dataLayer ? window.dataLayer : [];
			window.dataLayer.push(${JSON.stringify(dimensions)})
			window.dataLayer.push({ visitorType: visited ? 'returning' : 'new' })
		})();
		`,
				}}
			/>
		</Head>
	)
}

// initSentry()
function App(
	props: CustomAppProps & {
		info: {
			store: ClotinoStore
			locale: ClotinoLocale
			pageType?: 'HP' | 'content' | 'checkout'
		}
	}
) {
	const { Component, pageProps } = props
	const freshProps = useRefreshableServerSideProps(pageProps, Component)
	const router = useRouter()
	const storefrontContextValue = useStorefrontContextValue(props.info.store)

	const err = props.err ?? freshProps.err ?? null

	const config = getConfig().publicRuntimeConfig
	const canonical = useCanonicalUrl()

	const clotinoContext = {
		store: storeFromStringFallback(props.info.store),
		locale: localeFromStringFallback(router.locale ?? router.defaultLocale ?? ''),
	}

	const productsInfo = usePrepareProductsInfo(freshProps.productsInfo)

	const customization = useClotinoMotiveProviderValue(
		pageProps?.setCustomization,
		pageProps?.iconSet
	)

	let content: React.ReactNode = (
		<div id="main">
			<Head>
				<meta
					name="google-site-verification"
					content="dw6Nv2Ow-G7oYSzzl73LxeAOTttTf8-8Rlm4ekUEuJE"
				/>
			</Head>
			<GtmInit pageType={props.info.pageType ?? 'HP'} />
			{config?.baseUrl && (
				<DefaultSeo
					canonical={canonical.seo}
					facebook={{
						appId: config.facebookAppId,
					}}
					openGraph={{
						type: 'website',
						locale: router.locale,
						url: canonical.og,
						site_name: 'Clotino',
						images: [{ url: 'https://mgwdata.net/clotino/beta/og.png' }],
					}}
				/>
			)}
			{err ? <DefaultErrorPage statusCode={err.statusCode} /> : <Component {...freshProps} />}
		</div>
	)

	if (Component.noLayout) {
		return (
			<ClotinoContext.Provider value={clotinoContext}>
				<ClotinoMotiveContext.Provider value={customization}>
					<QueryClientProvider client={queryClient}>
						<ProductInfoContext.Provider value={productsInfo}>
							<CartModal>{content}</CartModal>
						</ProductInfoContext.Provider>
					</QueryClientProvider>
				</ClotinoMotiveContext.Provider>
			</ClotinoContext.Provider>
		)
	}

	if (Component.renderLayout) {
		content = Component.renderLayout(content, freshProps, Component)
	}

	return (
		<>
			<ClotinoContext.Provider value={clotinoContext}>
				<ClotinoMotiveContext.Provider value={customization}>
					<QueryClientProvider client={queryClient}>
						<StorefrontContext.Provider value={storefrontContextValue}>
							<ProductInfoContext.Provider value={productsInfo}>
								<CartModal>{content}</CartModal>
							</ProductInfoContext.Provider>
						</StorefrontContext.Provider>
					</QueryClientProvider>
				</ClotinoMotiveContext.Provider>
			</ClotinoContext.Provider>
		</>
	)
}

App.getInitialProps = async (app: AppContextType) => {
	// runs both on server and sometimes in browser
	return {
		info: appContextInfo(app),
	}
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default appWithI18n(App as any, i18nConfig)
