import { googleAnalytics as analytics, pixelAnalytics } from './analytics/analytics'

export const GA_TRACKING_ID = process.env.GA_TRACKING_ID ?? '<YOUR_GA_TRACKING_ID>'
export const GTM_TRACKING_ID = process.env.GTM_TRACKING_ID ?? '<YOUR_GTM_TRACKING_ID>'

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string) => {
	console.log('pageview')
	analytics('config', GA_TRACKING_ID, {
		page_path: url,
	})
	pixelAnalytics('track', 'PageView')
}

export const gtm = { pageview }
