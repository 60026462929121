import getConfig from 'next/config'
import { useRouter } from 'next/router'

export function getCanonicalUrl(
	context: { locale?: string; defaultLocale?: string },
	path: string
) {
	const { locale, defaultLocale } = context
	const config = getConfig().publicRuntimeConfig
	const localePrefix = locale && locale !== defaultLocale ? `/${locale}` : ''
	const home = `${config.baseUrl ?? ''}${localePrefix}`
	const canonical = `${home}${path}`
	const og = canonical
	const seo = canonical.indexOf('/product/') > -1 ? home : canonical
	return { og, seo }
}

export function useCanonicalUrl() {
	const { locale, defaultLocale, asPath } = useRouter()
	return getCanonicalUrl({ locale, defaultLocale }, asPath)
}
