module.exports = {
	contembers: [
		{
			name: 'content',
			url: 'https://api.contember.mangoweb.org/content/clotino/live',
			token: '6e2c64dd4db1f4bd49fcea760a085a1bd83cd03d',
			publicToken: '6e2c64dd4db1f4bd49fcea760a085a1bd83cd03d',
		},
		{
			name: 'tenant',
			url: 'https://api.contember.mangoweb.org/tenant',
			token: '2cd96881483a351610b6279e12879437e949041b',
			publicToken: '',
		},
	],
}
