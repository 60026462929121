import { useRouter } from 'next/router'
import React from 'react'
import { gtm } from '../utils/gtm'
import { CLOTINO_CURRENCY, CURRENCY } from '../utils/locales/locales'
import { queryClient } from '../utils/react-query'
import { CLOTINO_LOCALSTORAGE_CHECKOUT_ID_KEY } from '../utils/shared'
import { StorefrontContextValue } from '../utils/shopify/storefront'

export function useStorefrontContextValue(currencyCode: CLOTINO_CURRENCY | string) {
	const router = useRouter()
	let currency: CLOTINO_CURRENCY = 'usd'
	if (currencyCode in CURRENCY) {
		currency = currencyCode as CLOTINO_CURRENCY
	}
	const [storefrontContextValue, setStorefrontContext] = React.useState<StorefrontContextValue>({
		currency,
		checkoutId: null,
	})

	React.useEffect(() => {
		if (process.browser) {
			setStorefrontContext((old) => ({
				...old,
				checkoutId: window.localStorage.getItem(
					CLOTINO_LOCALSTORAGE_CHECKOUT_ID_KEY(currency)
				),
			}))
		}
	}, [currency])

	React.useEffect(() => {
		const handleRouteChange = (url: string) => {
			gtm.pageview(url)
		}
		router.events.on('routeChangeComplete', handleRouteChange)
		return () => {
			router.events.off('routeChangeComplete', handleRouteChange)
		}
	}, [router.events])

	const cancelCheckout = React.useCallback(() => {
		setStorefrontContext((old) => ({ ...old, checkoutId: null }))
		window.localStorage.removeItem(CLOTINO_LOCALSTORAGE_CHECKOUT_ID_KEY(currency)),
			setTimeout(() => {
				queryClient.invalidateQueries(['cart'])
			}, 100)
	}, [currency])

	return React.useMemo(() => {
		return { ...storefrontContextValue, setValue: setStorefrontContext, cancelCheckout }
	}, [cancelCheckout, storefrontContextValue])
}
