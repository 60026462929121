/* eslint-disable @typescript-eslint/no-var-requires */
const contember = require('./config/contember.local.js')
const fetch = require('isomorphic-fetch')

function loadLocales() {
	const config = contember.contembers[0]
	const { url, token } = config

	return fetch(url, {
		method: 'POST',
		body: JSON.stringify({
			variables: {},
			query: /* GraphQL */ `
				query {
					listTranslationKey(filter: { domain: { identifier: { eq: "web" } } }) {
						identifier
						note
						contentType
						values {
							value
							catalogue {
								identifier
							}
						}
					}
				}
			`,
		}),
		headers: {
			'Content-Type': 'application/json;charset=ut8-8',
			Authorization: `Bearer ${token}`,
		},
	})
		.then((res) => res.json())
		.then((data) => {
			const result = {}

			const keys = data.data.listTranslationKey
			keys.forEach((key) => {
				key.values.forEach((value) => {
					const [ns, ...rest] = key.identifier.split(':')
					const namespace = rest.length === 0 ? 'common' : ns

					result[value.catalogue.identifier] = result[value.catalogue.identifier] ?? {}
					result[value.catalogue.identifier][namespace] =
						result[value.catalogue.identifier][namespace] ?? {}

					if (rest.length === 0) {
						result[value.catalogue.identifier][namespace][ns] = value.value
					} else {
						const identifier = rest.join(':')
						result[value.catalogue.identifier][namespace][identifier] = value.value
					}
				})
			})
			return result
		})
		.catch(console.error)
}

let lastTime = 0
let last = null
function loadMemoLocales() {
	const oldness = new Date().getTime() - lastTime
	console.log({ oldness })
	if (!last || oldness > 30 * 1000) {
		console.log('refreshing translations')
		last = loadLocales()
		lastTime = new Date().getTime()
		last.then(() => {
			lastTime = new Date().getTime()
		})
	} else {
		console.log('old translations')
	}

	return last
}

module.exports = {
	loader: false,
	locales: ['en', 'cs', 'de', 'sk', 'nl'],
	defaultLocale: 'en',
	loadLocaleFrom: async (lang, ns) => {
		const db = await loadMemoLocales()
		// const local = await import(`./locales/${lang}/${ns}.json`).then((m) => m.default)

		return {
			// ...local,
			...db?.[lang]?.[ns],
		}
	},
	pages: {
		'*': ['common', 'commerce', 'cart', 'motive'],
	},
}
