import { LANGUAGE_CODE } from '../../../config/shop.local.js'
import { GetServerSidePropsContext } from 'next'
import { useRouter } from 'next/router'
import nookies from 'nookies'
import React from 'react'
import { DoSomething } from '../../errors/DoSomething'
import { NotFoundError } from '../../errors/NotFoundError'
import { CLOTINO_CURRENCY } from '../locales/locales'
import { CLOTINO_STORE_COOKIE_KEY } from '../shared'

type PropsBase = Record<string, unknown>

type MemoServerSideLoader<Props extends PropsBase> = (
	context: GetServerSidePropsContext,
	currency: CLOTINO_CURRENCY,
	locale: LANGUAGE_CODE
) => Promise<Props>

type MemoryServerKey = string | ((context: GetServerSidePropsContext) => string)

function defaultMemoryServerKey(context: GetServerSidePropsContext) {
	const cookies = nookies.get(context)
	const currency = (cookies[CLOTINO_STORE_COOKIE_KEY] ?? 'czk') as CLOTINO_CURRENCY
	const url = context.resolvedUrl.replace(/(\?|\&)json=$/, '')
	const locale = context.locale ?? context.defaultLocale ?? 'en'
	return JSON.stringify({ currency, url, locale })
}

type SSRCacheStatus = 'FRESH' | 'STALE' | 'HIT' | 'EXPIRED'

type Memory<Props extends PropsBase> = {
	cacheStatus: SSRCacheStatus
	timestamp: number
	props?: Props
	loading?: Promise<Props>
}

type MemoryRepo<Props extends PropsBase> = Record<string, Memory<Props>>

type SSRPropsExtension = { _ssrCache: { timestamp: number; status: SSRCacheStatus } }

const REFRESHING_TIMEOUT_COEF = Number(process.env.REFRESHING_TIMEOUT_COEF ?? 1)

export function useRefreshableServerSideProps<
	P extends Record<string, unknown>,
	C extends React.ComponentType
>(props: P | undefined | null, Component: C): P & Partial<SSRPropsExtension> {
	const [paused, setPaused] = React.useState(false)
	const router = useRouter()
	const currentUrl = router.asPath
	const currentLocale = router.locale ?? router.defaultLocale ?? 'en'
	const [fresh, setFresh] = React.useState({ Component, props, currentUrl, currentLocale })
	const visibility = 'hidden' // TODO useDocumentVisibility
	const hasSSRCache = props && '_ssrCache' in props

	React.useEffect(() => {
		if (visibility === 'hidden' || !hasSSRCache) {
			return
		}

		const insideCurrentUrl = currentUrl
		const insideCurrentLocale = currentLocale

		const url = new URL(currentUrl, window.location.href)
		url.searchParams.set('json', '')

		const maxTimeout = 24 * 60 * 60 * 1000 * REFRESHING_TIMEOUT_COEF
		let timeout = 30 * 1000 * (paused ? 20 : 1) * REFRESHING_TIMEOUT_COEF
		let lastTimeout: NodeJS.Timeout | null = null

		const update = async () => {
			try {
				const res = await fetch(url.pathname + url.search, {
					headers: { Accept: 'application/json' },
				})
				const props = await res.json()
				setPaused(false)
				setFresh({
					Component,
					props,
					currentUrl: insideCurrentUrl,
					currentLocale: insideCurrentLocale,
				})
			} catch (e) {
				setPaused(true)
				console.error('useRefreshableServerSideProps', e)
			}
		}

		let mounted = true
		const next = () => {
			update().then(() => {
				timeout = Math.min(maxTimeout, timeout * 1.2)
				if (lastTimeout) {
					clearTimeout(lastTimeout)
				}
				if (mounted) {
					lastTimeout = setTimeout(() => {
						next()
					}, timeout)
				}
			})
		}

		if (process.browser) {
			if (lastTimeout) {
				clearTimeout(lastTimeout)
			}
			lastTimeout = setTimeout(next, 500)
		}

		return () => {
			mounted = false
			if (lastTimeout) {
				clearTimeout(lastTimeout)
			}
		}
	}, [visibility, Component, paused, hasSSRCache, currentUrl, currentLocale])

	if (Component !== fresh.Component) {
		return (props ?? {}) as P
	}

	if (currentUrl !== fresh.currentUrl || currentLocale !== fresh.currentLocale) {
		return (props ?? {}) as P
	}

	return (fresh.props ?? {}) as P
}

export async function getCachedServerSideProps<Props extends PropsBase>(
	memory: MemoryRepo<Props>,
	context: GetServerSidePropsContext,
	key: MemoryServerKey,
	fn: MemoServerSideLoader<Props>,
	config = {
		staleUntil: 10 * 1000,
		freshUntil: 5 * 1000,
	}
): Promise<(Props & SSRPropsExtension) | 'handled' | 'notfound'> {
	const { json, ...query } = context.query

	const cookies = nookies.get(context)
	const currency = (cookies[CLOTINO_STORE_COOKIE_KEY] ?? 'czk') as CLOTINO_CURRENCY
	const locale = (context.locale ?? context.defaultLocale ?? 'en') as LANGUAGE_CODE

	const common = { query }

	const { staleUntil, freshUntil } = config

	const now = new Date().getTime()

	const k = typeof key === 'string' ? key : key(context)

	if (memory[k] && memory[k].timestamp && now < freshUntil + memory[k].timestamp) {
		return {
			_ssrCache: { status: 'FRESH', timestamp: memory[k].timestamp },
			...(memory[k].props as Props),
		}
	}

	memory[k] = memory[k] ?? {
		timestamp: 0,
		cacheStatus: 'EXPIRED' as const,
	}

	const m = memory[k]

	if (!m) {
		throw new Error()
	}

	const loading = (m.loading = m.loading || fn(context, currency, locale))

	m.loading.then((props) => {
		m.timestamp = new Date().getTime()
		m.props = props
		delete m.loading
	})

	if (m && now < staleUntil + m.timestamp) {
		return {
			_ssrCache: { status: 'STALE', timestamp: m.timestamp },
			...(m.props as Props),
		}
	}

	try {
		const data = await m.loading

		if (m.loading === loading) {
			delete m.loading
		}

		return { _ssrCache: { status: 'HIT', timestamp: m.timestamp }, ...common, ...data }
	} catch (e) {
		if (e instanceof DoSomething) {
			e.fromContext(context)
			return 'handled'
		} else if (e instanceof NotFoundError) {
			return 'notfound'
		} else {
			throw e
		}
	}
}

export function createRefreshableGetServerSideProps<Props extends PropsBase>(
	fn: MemoServerSideLoader<Props>,
	key: MemoryServerKey = defaultMemoryServerKey,
	config = {
		freshUntil: 10 * 1000, // 10 sec
		staleUntil: 24 * 60 * 60 * 1000, // 1 day
	}
) {
	const memory = {}

	const resolver = async (context: GetServerSidePropsContext): Promise<{ props: Props }> => {
		const props = await getCachedServerSideProps(memory, context, key, fn, config)

		if (props === 'handled') {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return { props: {} as any as Props }
		}

		if (props === 'notfound') {
			const { res } = context
			res.statusCode = 404
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			return { props: { err: { statusCode: 404 } } as any as Props }
		}

		if (!props) {
			throw new Error('Failed loading data with getCachedServerSideProps.')
		}

		if (String(context.req.headers.accept).startsWith('application/json')) {
			const { res } = context
			res.setHeader('Content-Type', 'application/json;charset=utf-8')
			res.statusCode = 200
			res.end(JSON.stringify(props))
		}

		return {
			props,
		}
	}

	return resolver
}

export const xcreateRefreshableGetServerSideProps: typeof createRefreshableGetServerSideProps = (
	fn
) => {
	return async (context) => {
		const cookies = nookies.get(context)
		const currency = (cookies[CLOTINO_STORE_COOKIE_KEY] ?? 'czk') as CLOTINO_CURRENCY
		const locale = (context.locale ?? context.defaultLocale ?? 'en') as LANGUAGE_CODE
		return {
			props: await fn(context, currency, locale),
		}
	}
}
