import React from 'react'
import shop from '../../../config/shop.local.js'

export type CLOTINO_CURRENCY = typeof shop['currencies'][number]['code']
export type CLOTINO_LANGUAGE = typeof shop['languages'][number]['code']

type CurrencyConfig = { code: CLOTINO_CURRENCY }
type LanguageConfig = { code: CLOTINO_LANGUAGE; currencies: CLOTINO_CURRENCY[] }

export const LANGUAGE: Record<CLOTINO_LANGUAGE, LanguageConfig> = {
	cs: { code: 'cs', currencies: ['czk'] },
	sk: { code: 'sk', currencies: ['eur'] },
	en: { code: 'en', currencies: ['usd', 'eur'] },
	de: { code: 'de', currencies: ['eur'] },
	nl: { code: 'nl', currencies: ['eur'] },
}

export const CURRENCY: Record<CLOTINO_CURRENCY, CurrencyConfig> = {
	czk: { code: 'czk' },
	eur: { code: 'eur' },
	usd: { code: 'usd' },
}

export const CurrencyContext = React.createContext<CLOTINO_CURRENCY | null>(null)
export const LanguageContext = React.createContext<CLOTINO_LANGUAGE | null>(null)

export function useCurrency() {
	const currency = React.useContext(CurrencyContext)
	return currency ? CURRENCY[currency] : null
}
